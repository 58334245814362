<template>
  <div>
    <b-row
      class="content-header white-background v-sticky-sidebar-container service_request_task"
      style="border-radius: 6px;"
    >
      <b-col
        class="content-header-right d-md-block"
        style="position: relative;"
        md="12"
        cols="12"
      >
        <b-card
          no-body
          class="mb-0"
        >
          <div class="urn-form-wrapper m-4 ">
            <div class="orn-form-header d-flex justify-content-between mb-75">
              <div class="d-form-logo_section" />
              <div class="d-form-no_section text-right">
                <h5>
                  {{ digitalForm.formNumber }}
                </h5>
                <h5>
                  {{ digitalForm.revision }}
                </h5>
              </div>
            </div>
            <div class="urn-repository-order_section">
              <div class="b-form-section-title my-2 margin-top-bottom-0">
                <h3>Redemption of ornament form 领取福位里的福物声明</h3>
              </div>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-class="label-width-340"
                    label-cols="12"
                    label-cols-md="6"
                    label-cols-lg="4"
                    label="Redemption of ornament form (Niche lot)*:"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ nicheLot || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="1"
                    label-cols-lg="1"
                    label="I*,"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      :class="{ 'signed': customerNameImage }"
                      style="border-bottom: 1px solid #000"
                    >
                      <b-img
                        v-if="customerNameImage"
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    label-class="label-width-340"
                    label-cols="12"
                    label-cols-md="6"
                    label-cols-lg="4"
                    label="Purchaser/authorized representative (NRIC:)* "
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerNRIC || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label-cols="12"
                    label-cols-md="2"
                    label-cols-lg="1"
                    label="I,"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      v-if="currentKinNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      :class="{ 'signed': kinNameImage }"
                      style="border-bottom: 1px solid #000"
                    >
                      <b-img
                        v-if="kinNameImage"
                        :src="kinNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    label-class="label-width-180"
                    label-cols="12"
                    label-cols-md="4"
                    label-cols-lg="3"
                    label="Next of Kin (NRIC:) "
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ kinNRIC || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-class="label-width-320"
                    label-cols="12"
                    label-cols-md="5"
                    label-cols-lg="5"
                    label="do hereby acknowledge retrieval of the ornament of the File No*"
                    label-for="input-default"
                    class="align-items-center"
                  >
                    <div
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ fileNo || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-form-warning-text-block">
                <p class="d-form-warning-text">
                  本人乃新加玻富贵山庄福位购买者 / 购买者之委托人（本人身份证号，文件号码，如上述）现领取福位里的福物。
                </p>
              </div>
              <b-row class="mt-5 margin-top-1">
                <b-col md="6">
                  <p class="d-form-signature-title">Purchaser / Authorized Representative Signatory
                    <br>
                    <span>购买者/受委托领瓮人签名</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!isPurchaserSign"
                        class="form-signature-text"
                      />
                      <b-img
                        v-else
                        :src="purchaserSign"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ customerName }}</p>
                    <p v-if="purchaserSignDate">
                      Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      verifiedSign: false,
      verifiedSignSrc: '',
      verifiedSignDate: '',
      verifiedSignName: '',
      customerNameImageError: false,
      kinNameImageError: false,
      urnCollapse: false,
      purchaserCollapse: true,
      editFormInOtherStatus: false,
      tabActive: 'email',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      customerNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      kinNameOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.kinSignaturePad.resizeCanvas() },
      },
      inventoryItems: [],
      splitQuantity: false,
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNRIC: '',
      currentKinNameType: 'input',
      kinName: '',
      kinNameImage: '',
      kinNRIC: '',
      urnFileNo: '',
      fileNo: '',
      nicheLot: '',
      numberOfUnit: '',
      dgcwbs: '',
      dgcwbsMpl: 0,
      dgcwbsNmg: 0,
      lgcwbs: '',
      lgcwbsMpl: 0,
      lgcwbsNmg: 0,
      lcwbs: '',
      lcwbsMpl: 0,
      lcwbsNmg: 0,
      pcll: '',
      pcllMpl: 0,
      pcllNmg: 0,
      byll: '',
      byllMpl: 0,
      byllNmg: 0,
      ro: '',
      roMpl: 0,
      roNmg: 0,
      pj: '',
      pjMpl: 0,
      pjNmg: 0,
      rj: '',
      rjMpl: 0,
      rjNmg: 0,
      others: '',
      othersName: '',
      othersMpl: 0,
      othersNmg: 0,
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      existingVerifiedSignSrcObject: {},
      existingPurchaserSignObject: {},
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Type your message here',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Type your message here',
      },
      required,
    }
  },
  created() {
    this.makeFormValues()
  },
  methods: {
    makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      const kinNameObject = this.serviceRequest.formValues.find(o => o.key === 'kinName')
      if (kinNameObject) {
        if (kinNameObject.value.startsWith('data:image/')) {
          this.kinNameImage = kinNameObject.value
          this.currentKinNameType = 'canvas'
        } else {
          this.currentKinNameType = 'input'
          this.kinName = kinNameObject.value
        }
      }
      this.kinNRIC = this.serviceRequest.formValues.find(o => o.key === 'kinNRIC')?.value
      if (this.requestForm.values.length) {
        const nicheLotObject = this.requestForm.values.find(o => o.key === 'nicheLot')
        if (nicheLotObject) {
          this.nicheLot = nicheLotObject.value
        }
        const numberOfUnitObject = this.requestForm.values.find(o => o.key === 'numberOfUnit')
        if (numberOfUnitObject) {
          this.numberOfUnit = numberOfUnitObject.value
        }
        const urnFileNoObject = this.requestForm.values.find(o => o.key === 'urnFileNo')
        if (urnFileNoObject) {
          this.urnFileNo = urnFileNoObject.value
        }
        const inventoryItemsObject = this.requestForm.values.find(o => o.key === 'inventoryItems')
        if (inventoryItemsObject) {
          this.inventoryItems = inventoryItemsObject.value
        }

        const splitQuantityObj = this.requestForm.values.find(o => o.key === 'splitQuantity')
        if (splitQuantityObj) {
          this.splitQuantity = splitQuantityObj.value
        }
        // const dgcwbsObject = this.requestForm.values.find(o => o.key === 'dgcwbs')
        // if (dgcwbsObject) {
        //   this.dgcwbs = dgcwbsObject.value
        // }
        // const dgcwbsNmgObject = this.requestForm.values.find(o => o.key === 'dgcwbsNmg')
        // if (dgcwbsNmgObject) {
        //   this.dgcwbsNmg = dgcwbsNmgObject.value
        // }
        // const dgcwbsMplObject = this.requestForm.values.find(o => o.key === 'dgcwbsMpl')
        // if (dgcwbsMplObject) {
        //   this.dgcwbsMpl = dgcwbsMplObject.value
        // }
        // const lgcwbsObject = this.requestForm.values.find(o => o.key === 'lgcwbs')
        // if (lgcwbsObject) {
        //   this.lgcwbs = lgcwbsObject.value
        // }
        // const lgcwbsNmgObject = this.requestForm.values.find(o => o.key === 'lgcwbsNmg')
        // if (lgcwbsNmgObject) {
        //   this.lgcwbsNmg = lgcwbsNmgObject.value
        // }
        // const lgcwbsMplObject = this.requestForm.values.find(o => o.key === 'lgcwbsMpl')
        // if (lgcwbsMplObject) {
        //   this.lgcwbsMpl = lgcwbsMplObject.value
        // }
        // const lcwbsObject = this.requestForm.values.find(o => o.key === 'lcwbs')
        // if (lcwbsObject) {
        //   this.lcwbs = lcwbsObject.value
        // }
        // const lcwbsNmgObject = this.requestForm.values.find(o => o.key === 'lcwbsNmg')
        // if (lcwbsNmgObject) {
        //   this.lcwbsNmg = lcwbsNmgObject.value
        // }
        // const lcwbsMplObject = this.requestForm.values.find(o => o.key === 'lcwbsMpl')
        // if (lcwbsMplObject) {
        //   this.lcwbsMpl = lcwbsMplObject.value
        // }
        // const pcllObject = this.requestForm.values.find(o => o.key === 'pcll')
        // if (pcllObject) {
        //   this.pcll = pcllObject.value
        // }
        // const pcllNmgObject = this.requestForm.values.find(o => o.key === 'pcllNmg')
        // if (pcllNmgObject) {
        //   this.pcllNmg = pcllNmgObject.value
        // }
        // const pcllMplObject = this.requestForm.values.find(o => o.key === 'pcllMpl')
        // if (pcllMplObject) {
        //   this.pcllMpl = pcllMplObject.value
        // }
        // const byllObject = this.requestForm.values.find(o => o.key === 'byll')
        // if (byllObject) {
        //   this.byll = byllObject.value
        // }
        // const byllNmgObject = this.requestForm.values.find(o => o.key === 'byllNmg')
        // if (byllNmgObject) {
        //   this.byllNmg = byllNmgObject.value
        // }
        // const byllMplObject = this.requestForm.values.find(o => o.key === 'byllMpl')
        // if (byllMplObject) {
        //   this.byllMpl = byllMplObject.value
        // }
        // const roObject = this.requestForm.values.find(o => o.key === 'ro')
        // if (roObject) {
        //   this.ro = roObject.value
        // }
        // const roNmgObject = this.requestForm.values.find(o => o.key === 'roNmg')
        // if (roNmgObject) {
        //   this.roNmg = roNmgObject.value
        // }
        // const roMplObject = this.requestForm.values.find(o => o.key === 'roMpl')
        // if (roMplObject) {
        //   this.roMpl = roMplObject.value
        // }
        // const pjObject = this.requestForm.values.find(o => o.key === 'pj')
        // if (pjObject) {
        //   this.pj = pjObject.value
        // }
        // const pjNmgObject = this.requestForm.values.find(o => o.key === 'pjNmg')
        // if (pjNmgObject) {
        //   this.pjNmg = pjNmgObject.value
        // }
        // const pjMplObject = this.requestForm.values.find(o => o.key === 'pjMpl')
        // if (pjMplObject) {
        //   this.pjMpl = pjMplObject.value
        // }
        // const rjObject = this.requestForm.values.find(o => o.key === 'rj')
        // if (rjObject) {
        //   this.rj = rjObject.value
        // }
        // const rjNmgObject = this.requestForm.values.find(o => o.key === 'rjNmg')
        // if (rjNmgObject) {
        //   this.rjNmg = rjNmgObject.value
        // }
        // const rjMplObject = this.requestForm.values.find(o => o.key === 'rjMpl')
        // if (rjMplObject) {
        //   this.rjMpl = rjMplObject.value
        // }
        // const othersObject = this.requestForm.values.find(o => o.key === 'others')
        // if (othersObject) {
        //   this.others = othersObject.value
        // }
        // const othersNameObject = this.requestForm.values.find(o => o.key === 'othersName')
        // if (othersNameObject) {
        //   this.othersName = othersNameObject.value
        // }
        // const othersNmgObject = this.requestForm.values.find(o => o.key === 'othersNmg')
        // if (othersNmgObject) {
        //   this.othersNmg = othersNmgObject.value
        // }
        // const othersMplObject = this.requestForm.values.find(o => o.key === 'othersMpl')
        // if (othersMplObject) {
        //   this.othersMpl = othersMplObject.value
        // }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const verifiedSignSrcObject = this.requestForm.values.find(o => o.key === 'verifiedSignSrc')
        if (verifiedSignSrcObject) {
          this.existingVerifiedSignSrcObject = verifiedSignSrcObject
          this.verifiedSignSrc = verifiedSignSrcObject.value
          this.verifiedSign = true
          this.verifiedSignDate = verifiedSignSrcObject.date
          this.verifiedSignName = verifiedSignSrcObject.name
        }
      }
    },
  },
}
</script>
<style scoped>
@media print {
  .urn-form-wrapper{
     margin-top: 1rem !important;
  }
  input[type=checkbox], input[type=radio] {
      opacity: 1 !important;
  }
   .margin-bottom-0{
       margin-bottom: 0 !important;
  }
  .margin-top-0{
       margin-top: 0 !important;
  }
  .margin-top-1{
       margin-top: 1rem !important;
  }
  .margin-top-bottom-0{
       margin-top: 0 !important;
       margin-bottom: 0 !important;
  }
}
</style>
