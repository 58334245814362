import { render, staticRenderFns } from "./RedemptionOfOrnament.vue?vue&type=template&id=f8eec938&scoped=true&"
import script from "./RedemptionOfOrnament.vue?vue&type=script&lang=js&"
export * from "./RedemptionOfOrnament.vue?vue&type=script&lang=js&"
import style0 from "./RedemptionOfOrnament.vue?vue&type=style&index=0&id=f8eec938&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8eec938",
  null
  
)

export default component.exports